import axios from 'axios';
import React from 'react';
import { BackArrow, RenameIcon, TimesIcon, TrashIcon, UntrashIcon } from './icons';
import { MarkThreeDocument } from './server';


interface NavigatorProps {
    setCurrentDocument :(currentDocument :string) => void
    closeModal:() => void
}

interface NavigatorState {
    documents :MarkThreeDocument[]
    viewingTrash :boolean
}

export class Navigator extends React.Component<NavigatorProps, NavigatorState> {
    constructor(props) {
        super(props);
        this.trashDocument = this.trashDocument.bind(this);
        this.untrashDocument = this.trashDocument.bind(this);
        this.newDocument = this.newDocument.bind(this);
        this.renameDocument = this.renameDocument.bind(this);

        this.state = {
            documents: [],
            viewingTrash: false,
        }
    }

    async componentDidMount() {
        let documents = (await axios.get('/document')).data as MarkThreeDocument[];

        this.setState({ documents });
    }

    async trashDocument(documentID :string) {
        await axios.delete(`/document/${documentID}`);

        let documents = (await axios.get('/document')).data as MarkThreeDocument[];

        this.setState({ documents });
    }

    async renameDocument(document :MarkThreeDocument) {
        let name = prompt("Please select a new name:", document.documentName || '');
        await axios.patch(`/document/${document.documentID}/name?newName=${name}`);
        let documents = (await axios.get('/document')).data as MarkThreeDocument[];
        this.setState({ documents });
    }

    async untrashDocument(documentID :string) {
        await axios.patch(`/document/${documentID}/untrash`);

        let documents = (await axios.get('/document')).data as MarkThreeDocument[];

        this.setState({ documents });
    }

    async newDocument() {
        let newDocumentID= (await axios.post('/document')).data.documentID;
        this.props.setCurrentDocument(newDocumentID);
    }

    render() {
        return <div className="navigator">
            <button className="back" onClick={this.props.closeModal}><BackArrow /></button>
            <a className="help" href="/help" target="_blank">Help</a>
            <a className="logout" href="/logout">Logout</a>
            <h1>{this.state.viewingTrash ? 'Trash' : 'Files'}</h1>
        {this.state.documents.filter(d => this.state.viewingTrash ? d.isTrashed : !d.isTrashed).map(d => <div className="file"><a className="filename" title={`Created On ${new Date(d.createdOn).toLocaleString()}`}
            onClick={() => this.props.setCurrentDocument(d.documentID)}>
                {d.documentName || new Date(d.createdOn).toLocaleDateString()}
        </a>
        <a title="Rename" className="file-action" onClick={(e) => {e.preventDefault(); this.renameDocument(d)}}><RenameIcon /></a>
        {!this.state.viewingTrash && <a title="Trash" className="file-action" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.trashDocument(d.documentID)}}><TrashIcon /></a>}
        {this.state.viewingTrash && <a title="Untrash" className="file-action" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.untrashDocument(d.documentID)}}><UntrashIcon /></a>}</div>
        )}
        <div className="buttons"><button className="button" onClick={() => this.setState({ viewingTrash: !this.state.viewingTrash})}>{this.state.viewingTrash ? 'View files' : 'View Trash'}</button>
        <button className="button is-primary" onClick={this.newDocument}>Create new</button>
        </div>
        </div>
    }
}